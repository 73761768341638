<template>
  <div class="main-div">
    <div class="welcome-main-div" v-if="model && model.item">
      <h1 class="birth-user-name">
        {{ model.item.description }}
      </h1>
    </div>

  </div>
</template>

<script>
import store from "../store/index";
export default {
  name: "welcome",
  props: ["data"],
  data() {
    return {
      destroyed: false,
      fileUrl: '/images/bg/welcome.png'
    };
  },
  created() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      if (this.model.item.image) {
        this.fileUrl = process.env.VUE_APP_BASE_CDN_URL + '/' + this.model.item.image.fileName;
      }
      else{
        this.fileUrl = process.env.VUE_APP_BASE_CDN_URL + '/' + store.state.logo;
      }
      setTimeout(() => {
        store.dispatch('setNextPlaylist')
        return;
      }, (this.model.item.displaySecond || 30) * 1000)
    }
  },
  computed: {
    model() {
      return this.$store.state.welcome;
    },
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
.main-div {
  position: fixed;
  width: 100%;
  height: 100%;
}

.welcome-main-div {
  background-image: url('/images/bg/welcome.png');
  background-position: center;
  background-size: 100% 100%;
}

.welcome-main-div img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 71px;
  border: 15px solid white;
  border-radius: 10px;
}

.welcome-main-div {
  width: 100%;
  height: 100%;
  position: relative;
}

.welcome-main-div h1 {
    position: absolute;
    left: 1100px;
    margin: auto;
    text-align: center;
    top: 140px;
    font-size: 70px;
    margin-left: 0;
    font-size: 110px;
    font-weight: 800;
    color: black;
    text-transform: uppercase;
}
</style>
